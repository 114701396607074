import React from "react"
import styled from "styled-components"

const AnimateUp = styled.div`
  opacity: 0;
  animation: HeroAnimation 0.8s ${props => props.delay} forwards
    cubic-bezier(0.2, 0.8, 0.2, 1);
  @keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export default AnimateUp
