import styled from "styled-components"
import Layout from "./layout"
import React from "react"

const APP_STORE_LINK =
  "https://itunes.apple.com/us/app/shotty/id1250306151?mt=12"

const AppStoreButton = props => (
  <Group {...props}>
    <Link href={APP_STORE_LINK}>
      <Badge src={require("../images/app-store-badge-inner.svg")} />
    </Link>
  </Group>
)

export default AppStoreButton

const Group = styled.div`
  opacity: 0;
  animation: HeroAnimation 3s 0.3s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  margin: 20px 0;
  :hover {
    transform: translateY(-3px);
    cursor: pointer;
  }

  @keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const Link = styled.a``

const Badge = styled.img`
  background: black;
  height: 61px;
  background: url("../images/app-store-badge-inner.svg");
  outline: none;
  border: none;
  border-radius: 12px;
  border: 2px solid #24263f;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  :hover {
    cursor: pointer;
    transform: translateY(-3px);
  }
`
