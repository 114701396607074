import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import AppStoreButton from "../../components/AppStoreButton"
import Header from "../../components/header"
import GlobalStyle from "../../components/GlobalStyle"
import Theme from "../../components/Theme"
import AnimateUp from "../../components/AnimateUp"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default props => (
  <Theme>
    <Layout>
      <GlobalStyle />
      <SEO
        title="Customize Where Your Mac Saves Screenshots"
        description="Keep a clean desktop. Tell your Mac to save screenshots in any folder you'd like. It's super easy."
        image="/images/twitter-card-customize-folder.png"
      />
      <Header />
      <Styles>
        <HeroSection>
          <div className="text-section">
            <AnimateUp delay="0.1s">
              <h6>PRO TIP</h6>
            </AnimateUp>
            <AnimateUp delay="0.3s">
              <h1 className="title">
                How to customize the folder where your screenshots are saved.
              </h1>
            </AnimateUp>
          </div>
          <AnimateUp delay="0.5s">
            <div className="image-section">
              <Img
                fluid={props.data.laptopImage.childImageSharp.fluid}
                critical={true}
                className="laptop-image"
              />
            </div>
          </AnimateUp>
        </HeroSection>
        <HeroSection>
          <div className="text-section">
            <h1 className="title">Declutter your Desktop</h1>
            <p className="body">
              Here’s how you can set your Mac to save screenshots to a custom
              folder. Shotty will keep working normally.
            </p>
          </div>
        </HeroSection>
        <TwoColSection
          subtitle="Step 1"
          title="Press Command+Shift+5"
          body="This will bring up Apple’s screenshot tool."
          image={props.data.screenshotImage1.childImageSharp.fluid}
        />
        <TwoColSection
          subtitle="Step 2"
          title="Click “Options”"
          body="You’ll set your new folder in the next step."
          image={props.data.screenshotImage2.childImageSharp.fluid}
        />
        <TwoColSection
          subtitle="Step 3"
          title="Choose “Other location...”"
          body="Choose any folder on your Mac, or create a new one. This is where your screenshots will be saved from now on. You can change this at any time."
          image={props.data.screenshotImage3.childImageSharp.fluid}
        />
        <HeroSection>
          <div className="text-section">
            <h6 className="caption">SWEET!</h6>
            <h1 className="title">
              Shotty will show screenshots as they’re added to the new folder.
            </h1>
          </div>
          <div className="image-section">
            <Img
              fluid={props.data.shottyImage.childImageSharp.fluid}
              className="shotty-image"
            />
            <CTASection>
              <AppStoreButton />
            </CTASection>
          </div>
        </HeroSection>
      </Styles>
      {/* <Footer>
      <p className="Tagline">
        <a href="https://www.jacobruiz.design/">Made on Earth.</a>
      </p>
    </Footer> */}
    </Layout>
  </Theme>
)

export const pageQuery = graphql`
  query {
    laptopImage: file(relativePath: { eq: "custom-folder/laptop-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1027) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    shottyImage: file(relativePath: { eq: "custom-folder/shotty-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 615) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    screenshotImage1: file(relativePath: { eq: "custom-folder/keyboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 634) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    screenshotImage2: file(relativePath: { eq: "custom-folder/options.png" }) {
      childImageSharp {
        fluid(maxWidth: 634) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    screenshotImage3: file(relativePath: { eq: "custom-folder/menu.png" }) {
      childImageSharp {
        fluid(maxWidth: 634) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
const Styles = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0 20px;
  -webkit-font-smoothing: antialiased;

  display: flex;
  flex-direction: column;
  align-content: center;
  padding-top: 80px;
  font-size: 24px;
  line-height: 42px;
`

const CTASection = styled.div`
  margin: 50px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
`

const HeroSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  margin: 40px 0;

  .text-section {
    max-width: 700px;
    margin: 20px auto;

    .caption {
      font-size: 15px;
      font-weight: 800;
      letter-spacing: 0.24em;
      /* color: ${props => props.theme.accent}; */
      margin: 0;
      text-transform: uppercase;
    }
    .title {
      font-size: 42px;
    }
  }

  .laptop-image {
    margin: 0 auto;
    max-width: 1027px;
  }
  .shotty-image {
    max-width: 650px;
    margin: 0 auto;
  }

  @media (max-width: 400px) {
    .text-section {
      .title {
        font-size: 32px;
        line-height: 1.3;
      }
    }
  }
`

const TwoColSection = ({ subtitle, title, body, image }) => (
  <Section>
    <div className="text-section">
      <h3 className="caption">{subtitle}</h3>
      <h3 className="title">{title}</h3>
      <p className="body">{body}</p>
    </div>
    <div className="image-section">
      <Img fluid={image} className="screenshot-image" />
    </div>
  </Section>
)

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 30px 0;
  .text-section {
    align-self: center;
    justify-self: center;
    max-width: 454px;
    padding: 0 20px 0 0;

    .caption {
      margin: 0;
      font-size: 32px;
      color: #b0b0b0;
    }
    .title {
      margin: 0;
      font-size: 32px;
    }
    .body {
      margin: 20px 0 0 0;
    }
  }
  .screenshot-image {
    max-width: 634px;
  }

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
    .text-section {
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0 0 20px 0;
    }
    .screenshot-image {
      margin: 0 auto;
      max-width: 634px;
    }
  }
`
